var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-component-modal',{attrs:{"modalTitle":_vm.$t('Components.Events.EditDateTime.Main_Title'),"onClickCancel":_vm.onClickCancel,"showModal":_vm.showModal,"onClickSave":_vm.saveEventDateTime,"isSaving":_vm.isSaving,"isSavingSuccess":_vm.isSavingSuccess,"isSavingError":_vm.isSavingError,"modalSize":"dynamic"},on:{"closeModal":_vm.onClickCancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('message',{attrs:{"message":_vm.$t('Components.Events.EditDateTime.Error_SelectDates')}}),_c('div',{staticClass:"date-input"},[_c('div',{staticClass:"date"},[_c('ui-checkbox',{staticClass:"has-margin-bottom",attrs:{"label":_vm.$t('Components.Events.EditDateTime.Label_MultiDay')},model:{value:(_vm.multiDayCheck),callback:function ($$v) {_vm.multiDayCheck=$$v},expression:"multiDayCheck"}}),_c('div',{staticClass:"date-table"},[(_vm.dates)?_c('v-date-picker',{attrs:{"mode":_vm.calendarViewType,"columns":_vm.$screens({ default: 1, sm: 2 }),"is-inline":true,"locale":_vm.$i18n.locale},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}):_vm._e()],1)],1),_c('div',{staticClass:"time"},[_c('p',{staticClass:"title is-3"},[_vm._v(" "+_vm._s(_vm.$t('Components.Events.EditDateTime.Title_Bold_TimeSettings'))+" ")]),_c('ui-checkbox',{staticClass:"has-margin-bottom",attrs:{"label":_vm.$t('Components.Events.EditDateTime.Label_AllDay')},model:{value:(_vm.allDayCheck),callback:function ($$v) {_vm.allDayCheck=$$v},expression:"allDayCheck"}}),_c('div',{staticClass:"select-section"},[_c('div',{staticClass:"select",class:[
              { disabled: _vm.allDayCheck },
              { 'is-danger': _vm.endTimeErrors.length },
            ]},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.startMinutes),expression:"startMinutes"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.startMinutes=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.timeOptions),function(startMinutes){return _c('option',{key:startMinutes.id,domProps:{"value":startMinutes}},[_vm._v(" "+_vm._s(_vm._f("minutesToTime")(startMinutes))+" ")])}),0)]),_c('span',{staticClass:"horizontal-line"}),_c('div',{staticClass:"select",class:[
              { disabled: _vm.allDayCheck },
              { 'is-danger': _vm.endTimeErrors.lenth },
            ]},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.endMinutes),expression:"endMinutes"}],attrs:{"error":_vm.endTimeErrors},on:{"input":function($event){return _vm.$v.endMinutes.$touch()},"blur":function($event){return _vm.$v.endMinutes.$touch()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.endMinutes=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.timeOptions),function(endMinutes){return _c('option',{key:endMinutes.id,domProps:{"value":endMinutes}},[_vm._v(" "+_vm._s(_vm._f("minutesToTime")(endMinutes))+" ")])}),0)])]),(_vm.endTimeErrors.length)?_c('div',{staticClass:"has-text-danger",domProps:{"innerHTML":_vm._s(_vm.endTimeErrors)}}):_vm._e()],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }