<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EditDateTime.Main_Title')"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveEventDateTime"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    @closeModal="onClickCancel"
    modalSize="dynamic"
  >
    <template v-slot:content>
      <message
        :message="$t('Components.Events.EditDateTime.Error_SelectDates')"
      />
      <div class="date-input">
        <div class="date">
          <ui-checkbox
            class="has-margin-bottom"
            :label="$t('Components.Events.EditDateTime.Label_MultiDay')"
            v-model="multiDayCheck"
          />
          <div class="date-table">
            <v-date-picker
              v-if="dates"
              :mode="calendarViewType"
              v-model="dates"
              :columns="$screens({ default: 1, sm: 2 })"
              :is-inline="true"
              :locale="$i18n.locale"
            >
            </v-date-picker>
          </div>
        </div>
        <div class="time">
          <p class="title is-3">
            {{ $t('Components.Events.EditDateTime.Title_Bold_TimeSettings') }}
          </p>
          <ui-checkbox
            class="has-margin-bottom"
            :label="$t('Components.Events.EditDateTime.Label_AllDay')"
            v-model="allDayCheck"
          />
          <div class="select-section">
            <div
              class="select"
              :class="[
                { disabled: allDayCheck },
                { 'is-danger': endTimeErrors.length },
              ]"
            >
              <select v-model="startMinutes">
                <option
                  v-for="startMinutes in timeOptions"
                  :key="startMinutes.id"
                  :value="startMinutes"
                >
                  {{ startMinutes | minutesToTime }}
                </option>
              </select>
            </div>
            <span class="horizontal-line"></span>
            <div
              class="select"
              :class="[
                { disabled: allDayCheck },
                { 'is-danger': endTimeErrors.lenth },
              ]"
            >
              <select
                v-model="endMinutes"
                :error="endTimeErrors"
                @input="$v.endMinutes.$touch()"
                @blur="$v.endMinutes.$touch()"
              >
                <option
                  v-for="endMinutes in timeOptions"
                  :key="endMinutes.id"
                  :value="endMinutes"
                >
                  {{ endMinutes | minutesToTime }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="endTimeErrors.length"
            class="has-text-danger"
            v-html="endTimeErrors"
          ></div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Message from '@/components/UI/Message'
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'

import { minValue } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {
    message: Message,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      startDate: new Date(
        this.$store.getters['eventStore/getEventData'].StartDate
      ),
      endDate: new Date(this.$store.getters['eventStore/getEventData'].EndDate),
      startMinutes: this.$store.getters['eventStore/getEventData'].StartMinutes,
      endMinutes: this.$store.getters['eventStore/getEventData'].EndMinutes,

      calendarViewType: '',
      timeOptions: this.buildTimeMinutesOptions(0, 1440, 5),
      multiDayCheck: false,
      allDayCheck: false,
      dates: null,
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      endMinutes: {
        minValue: (value) => value > this.startMinutes,
      },
    }
    return output
  },

  computed: {
    endTimeErrors() {
      let errors = ''
      if (!this.$v.endMinutes.$dirty) return errors
      if (!this.$v.endMinutes.minValue) {
        errors = this.$t('Components.Events.EditDateTime.Message_TimeError')
      }
      return errors
    },
  },

  watch: {
    multiDayCheck(val) {
      if (val) {
        // Is set to multiple days
        this.calendarViewType = 'range'
        if (this.dates.hasOwnProperty('start')) {
          this.dates = {
            start: this.dates.start,
            end: this.dates.end,
          }
        } else {
          this.dates = {
            start: this.dates,
            end: this.dates,
          }
        }
      } else {
        // Is set to single day
        this.calendarViewType = 'single'
        this.dates = this.dates.start
      }
    },

    allDayCheck(val) {
      if (val) {
        this.startMinutes = 0
        this.endMinutes = 1440
      }
    },
  },

  created() {
    this.multiDayCheck = this.$options.filters.daysBetweenDates(
      this.startDate,
      this.endDate
    )
      ? true
      : false
    this.calendarViewType = this.multiDayCheck ? 'range' : 'single'

    if (this.multiDayCheck) {
      this.dates = {
        start: this.startDate,
        end: this.endDate,
      }
    } else {
      this.dates = this.startDate
    }

    this.allDayCheck = this.startMinutes === 0 && this.endMinutes === 1440
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    buildTimeMinutesOptions(open, close, minutesSteps) {
      let output = []
      let oneMinute = 1

      for (let i = open; i <= close; i = i + minutesSteps) {
        output.push(i)
      }
      return output
    },

    setStartMinutes(value) {
      this.startMinutes = value
    },
    setEndMinutes(value) {
      this.endMinutes = value
    },

    saveEventDateTime() {
      let self = this

      if (!this.isSaving) {
        this.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
          let eventData = this.$objectHelper.cleanSource(
            this.$store.getters['eventStore/getEventData']
          )

          // Set date
          eventData.StartDate =
            this.$options.filters.dateObjectIsoDateString(
              !this.multiDayCheck ? this.dates : this.dates.start
            ) + 'T13:00:00'
          eventData.EndDate =
            this.$options.filters.dateObjectIsoDateString(
              !this.multiDayCheck ? this.dates : this.dates.end
            ) + 'T13:00:00'

          // Set minutes
          eventData.StartMinutes = this.startMinutes
          eventData.EndMinutes = this.endMinutes

          eventProvider.methods
            .updateEvent(eventData)
            .then((response) => {
              if (response.status === 200) {
                self.isSavingSuccess = true
                self.setEventData(response.data)

                let t = setTimeout(() => {
                  self.onClickCancel()
                  clearTimeout(t)
                }, 1500)
              }
            })
            .catch((e) => {
              ////console.log(error.response)
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.date-input {
  display: flex;
  flex-direction: row;

  .date {
    margin-right: ($gap * 2);
  }

  @media screen and (max-width: 810px) {
    flex-direction: column;
    .date {
      margin-right: 0;
      margin-bottom: ($gap * 2);
    }
  }

  .time {
    .select-section {
      display: flex;
      align-items: center;
      .disabled {
        pointer-events: none;
        > select {
          background-color: rgba($grey, 0.2);
        }
      }
      .horizontal-line {
        margin: 0 10px;
        width: 20px;
        height: 3px;
        background-color: $grey;
      }
    }
  }
}
</style>
